import React from 'react';
import { graphql } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import { PageHeader } from '../components/PageHeader';
import * as AcfLayout from '../components/Acf';
import SEO from '../components/SEO';
import { decodeEntities, stripHtmlTags } from '../utils/helpers';

const AcfComponent = ({ location, componentName, item, pageContext }) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName];
  const componentTitle = item.__typename ? item.__typename.replace('WordPressAcf_','') : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The <strong>"{componentTitle}"</strong> component is missing. <span>&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }

};

const Page = ({ data, location, pageContext }) => {
  const { wordpressPost: page, site, wordpressWpSettings, siteSettings } = data;
  const { options = null } = siteSettings
  const { wordpressUrl } = wordpressWpSettings
  if (!page) return null;
  const { title, yoast, excerpt, acf = {} } = page;
  const { layout } = acf;
  const { title: siteTitle } = wordpressWpSettings;
  return (
    <Layout location={location} wordpressUrl={wordpressUrl} includeCss={options.customCss}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        location={location}
      />
      {layout ? layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_','');
        return (
          <AcfComponent
            key={index}
            componentName={layoutComponentName}
            item={item}
            location={location}
            pageContext={pageContext}
          />
        );
      }) : <PageHeader headerTitle="There is no content on this page yet. Add content in WordPress." />}
    </Layout>
  )
}

export default Previewable(Page, 'page');
//export default (Page);

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressWpSettings {
      title
      siteUrl
      wordpressUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
      acf {
        layout: layout_page {
          __typename
          ... PostFeedQuery
          ... ContentPageHeaderQuery
          ... ImageAndContentQuery
          ... TwoTierImageAndContentQuery
          ... AccordionQuery
          ... TabsQuery
          ... TestimonialsGridQuery
          ... GlobalCtaQuery
        }
      }
    }
  }
`
