import React from 'react';
import './TestimonialsGrid.scss';
import { Image } from '../../Image'
import RenderContent from '../../RenderContent'

export const TestimonialsGrid = ({gridItems}) => {
  return (
    <section className="testimonials-grid">
      <div className="wrap">
        <div className="inner">
          {gridItems && gridItems.map((item, index) =>
            <div className="item" key={index}>
              <div className="inner">
                <div className="image"><Image src={item.image} /></div>
                <div className="content">
                  <RenderContent content={item.content}/>
                  {item.clientName && <strong className="name" dangerouslySetInnerHTML={{__html:item.clientName}} />}
                  {item.position && <span className="position" dangerouslySetInnerHTML={{__html:item.position}} />}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
