import React, { Component } from 'react';
import GatsbyLink from '../GatsbyLink';
import Modal from '../Modal';
import './VideoModalButton.scss';

class VideoModalButton extends Component {
  state = {
    showModal: false
  };

  openModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: true });
    document.body.classList.add('modal-open');
  }

  closeModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: false });
    document.body.classList.remove('modal-open');
  }

  render() {
    const { content } = this.props;
    const { showModal } = this.state;
    return (
      <div className="video-modal">
        <GatsbyLink to="#open-video" onClick={event => this.openModal(event)} aria-label="Watch Video" className="video-button" />
        <Modal
          active={showModal}
          closeModal={this.closeModal}
        >
          {content}
        </Modal>
      </div>
    );
  }
}

export default VideoModalButton
