import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const GoogleMap = (props) => {
  const { data } = props
  const { googleMap } = data.wordpressAcfOptions.options.locations[0]
  return (
    <iframe src={googleMap} frameBorder="0" allowFullScreen />
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressAcfOptions {
          options {
            locations {
              googleMap
            }
          }
        }
      }
    `}
    render={data => <GoogleMap data={data} {...props} />}
  />
)
