import React from 'react';
import Concertina from '../../Concertina';
import './Accordion.scss';

export const Accordion = (props) => {
  const {
    heading,
    accordionItems
  } = props;
  return (
    <section className="accordion">
      <div className="wrap">
        <h3>{heading}</h3>
        <Concertina concertina={accordionItems}/>
      </div>
    </section>
  );
}
