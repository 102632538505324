import React from 'react';
import { Image } from '../../Image';
import './ImageAndContent.scss';
import { decodeEntities } from '../../../utils/helpers';
import GatsbyLink from '../../GatsbyLink';
import RenderContent from '../../RenderContent'
import VideoModalButton from '../../VideoModalButton'

export const ImageAndContent = (props) => {
  const {
    arrangement,
    background,
    imageShift,
    image,
    content,
    iconList,
    video
  } = props;
  return (
    <section className={`image-and-content ${arrangement} background-${background}${imageShift ? ` image-${imageShift}` : ''}`}>
      <div className="wrap">
        <div className={`image-container`}>
          <div className="inner">
            <Image src={image} />
            {video && <VideoModalButton content={video}/>}
          </div>
        </div>
        <div className="content">
          <div className="the-content" dangerouslySetInnerHTML={{__html: content}} />
          {iconList.length > 0 &&
            <div className="icon-list">
              {iconList && iconList.map((icon, index) =>
                <div className="item" key={index}>
                  <div className="icon"><Image src={icon.icon}/></div>
                  <RenderContent content={icon.content} />
                </div>
              )}
            </div>
          }
        </div>
      </div>
    </section>
  );
}
