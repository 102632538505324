import React from 'react';
import './ImageAndTestimonial.scss';

export const ImageAndTestimonial = (props) => {
  return (
    <section className="content-page-header">
      <div className="wrap">
        ImageAndTestimonial
      </div>
    </section>
  );
}
