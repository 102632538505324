import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import './GlobalCta.scss';
import { Image } from '../../Image'
import Leads from '../../Leads'
import RenderContent from '../../RenderContent'

const GlobalCtaPass = (props) => {
  const { data, customCta, title, content,  } = props
  const { options } = data.wordpressAcfOptions
  const titleToUse = customCta ? title : options.globalCtaTitle
  const contentToUse = customCta ? content : options.globalCtaContent
  return (
    <section className={`global-cta`}>
      <div className="wrap">
        <div className="inner">
          <div className="left">
            <h2 dangerouslySetInnerHTML={{__html:titleToUse}} />
            <RenderContent content={contentToUse}/>
          </div>
          <div className="right">
            <div className="inner">
              <Leads id="7"/>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export const GlobalCta = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressAcfOptions {
            options {
              globalCtaTitle
              globalCtaContent
            }
          }
        }
      `}
      render={data => <GlobalCtaPass {...props} data={data} />}
    />
  );
}
