import React, { Component } from 'react';
import RenderContent from '../../../components/RenderContent';
import './Tabs.scss';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { decodeEntities, isClient } from '../../../utils/helpers';
import { Image } from '../../../components/Image'
import GatsbyLink from '../../../components/GatsbyLink'


class ThisTabs extends Component {
  state = { openNavKey: 0 };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: 0 });
    setTimeout(function() { scrollTo(`#tabTop-${openNavKey}`); }, 400);
    return this.setState({ openNavKey });
  }

  render() {
    const { tabs, title } = this.props;
    const { openNavKey } = this.state;
    if (!tabs) return null
    return (
      <section className="tabs">
        <div className="navigation">
          <div className="inner">
            {tabs && tabs.map((tab, index) =>
              <div className={`link${openNavKey === index ? ' active' : ''}`} key={index} onClick={event => this.toggleSubNav(event, index)}>
                <span>{tab.tabLabel}</span>
              </div>
            )}
          </div>
        </div>
        <div className="tab-container">
          {tabs && tabs.map((tab, index) =>
            <div key={index} className={`tab${openNavKey === index ? ' open' : ' closed'} ${tab.arrangement}`}>
              <div id={`tabTop-${index}`} className="anchour"/>
              <div className="content">
                <div className="wrap">
                  <div className="text">
                    <h3 dangerouslySetInnerHTML={{ __html: decodeEntities(tab.heading) }} />
                    <RenderContent content={`${tab.content}`}/>
                    {tab.cta && <GatsbyLink to={tab.cta.url} className="action" target={tab.cta.target}>{tab.cta.title}</GatsbyLink> }
                  </div>
                  <div className="image"><Image src={tab.image} /></div>
                </div>
              </div>
            </div>
          )}
        </div>


      </section>
    );
  }
}


export const Tabs = (props) => {
  return (
    <ThisTabs {...props} />
  )
}

export default Tabs;
