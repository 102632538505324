import React from 'react';
import './TwoTierImageAndContent.scss';
import GatsbyLink from '../../GatsbyLink'
import { Image } from '../../Image'
import RenderContent from '../../RenderContent'
export const TwoTierImageAndContent = (props) => {
  const {
    contentOne,
    contentTwo,
    contentThree,
    image,
    template
  } = props

  const layout = () => {
    switch(template) {

      case 'template-1':
      return (
        <>
          <div className="tier-one">

            <div className="left">
              <RenderContent content={contentOne} />
            </div>

            <div className="content">
              <RenderContent content={contentTwo} />
            </div>

          </div>

          <div className="tier-two">

            <div className="image">
              <div className="inner">
                <Image src={image} />
              </div>
            </div>

            <div className="inner">
              <div className="content">
                <RenderContent content={contentThree} />
              </div>
             </div>
           </div>
        </>
      );
      break;

      case 'template-2':
      return (
        <>
          <div className="tier-one">

            <div className="left">
              <RenderContent content={contentOne} />
            </div>

            <div className="content">
              <RenderContent content={contentTwo} />
            </div>

          </div>

          <div className="tier-two">

            <div className="image">
              <div className="inner">
                <Image src={image} />
              </div>
            </div>

            <div className="inner">
              <div className="content">
                <RenderContent content={contentThree} />
              </div>
             </div>
           </div>
        </>
      )
      break;

      default:
        return (
          <>
            <div className="tier-one">

              <div className="left">
                <RenderContent content={contentOne} />
              </div>

              <div className="content">
                <RenderContent content={contentTwo} />
              </div>

            </div>

            <div className="tier-two">

              <div className="image">
                <div className="inner">
                  <Image src={image} />
                </div>
              </div>

              <div className="inner">
                <div className="content">
                  <RenderContent content={contentThree} />
                </div>
               </div>
             </div>
          </>
        );
    }
  }
  return (
    <section className={`two-tier-image-and-content ${template}`}>
      <div className="wrap">
        {layout(template)}
      </div>
    </section>
  );
}
