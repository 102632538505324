import React, { useState } from 'react';
import { Image } from '../../Image';
import HomePageImageQuery from '../../Image/HomePageImageQuery'
import Carousel from '../../Carousel';
import './ContentPageHeader.scss';
import { decodeEntities } from '../../../utils/helpers';
import GatsbyLink from '../../GatsbyLink';
import { SideNavigation } from '../../SideNavigation';
import { Breadcrumbs } from '../../Breadcrumbs';
import { RenderShortcode } from '../../RenderShortcode';
import LazyLoad from 'react-lazy-load';
import GoogleMap from './GoogleMap'

const CarouselNav = ({items, sliderRef, currentIndex}) => {
  const onTabClick = (index) => {
    sliderRef.goTo(index);
  }
  return (
    <div className="homepage-header-carousel-nav">
      {items && items.map((tabLabel, index) =>
        <div
          className={`homepage-header-carousel-nav-item ${currentIndex === index ? 'active' : ''}`}
          onClick={() => onTabClick(index)}
          key={index}
        >
          <span>{tabLabel}</span>
        </div>
        )}
    </div>
  );
}

const CarouselSlide = ({slide}) => {
  const { slideHeading, slideSubheading, slideContent, slideCta } = slide;
    return (
      <div className="homepage-header-slide" key={slideHeading}>
        {slideSubheading && <span className="homepage-header-slide-subheading">{decodeEntities(slideSubheading)}</span>}
        {slideHeading && <h2 className="homepage-header-slide-heading">{decodeEntities(slideHeading)}</h2>}
        <div className="homepage-header-slide-content">
          {slideContent.map((column, index) => {
            const { column: content } = column;
            return (
              <div className="homepage-header-slide-column" key={index} dangerouslySetInnerHTML={{__html: content }} />
            );
          })}
        </div>
        {slideCta ? (
          <div className="homepage-header-slide-cta">
            <GatsbyLink className="action" to={slideCta.url}>{slideCta.title}</GatsbyLink>
          </div>
        ) : null}
      </div>
    );
}

export const ContentPageHeader = (props) => {
  const [sliderRef, setSlider] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    title,
    title_tag,
    subtitle,
    sub_title_tag,
    heading,
    mainImage,
    secondaryImage,
    slider,
    content_below_header_type,
    contentBelowHeading,
    contentBelowRows,
    pageContext,
    breadcrumbs,
    location,
    useMap
  } = props;

  const TitleTag = `${title_tag}`;
  const SubTitleTag = `${sub_title_tag}`;
  const sliderSettings = {
    container: "homepage-header-carousel",
    controlsContainer: '.tns-controls',
    nav: false,
    onInit: (thisSlider) => {
      setSlider(thisSlider);
    }
  };
  const sliderTabLabels = slider && slider.map(slide => slide.sliderTabLabel);
  const splitHeading = title.split('<br />')

  return (
    <section className="page-header">
      <div className="main-header">
        <div className="wrap">

          <div className={`left-content${location.pathname === "/" ? ' home' : ''}`}>
            {splitHeading.length === 3 ?
              <>
                <TitleTag className="page-title">{splitHeading.map((line, index) => <span key={index} dangerouslySetInnerHTML={{__html: line }} />)}</TitleTag>
                <SubTitleTag className="page-sub-title">{decodeEntities(subtitle)}</SubTitleTag>
              </>
            :
              <>
              <TitleTag className="page-title" dangerouslySetInnerHTML={{__html: decodeEntities(title)}} />
              <SubTitleTag className="page-sub-title" dangerouslySetInnerHTML={{__html: decodeEntities(subtitle)}} />
              </>
            }
          </div>

          <div className="main-homepage-header-image">
            {breadcrumbs && <Breadcrumbs location={location}/>}
            <div className="inner">
              {useMap ?
                <>
                  <LazyLoad>
                    <GoogleMap/>
                  </LazyLoad>
                  <div className="loading"/>
                </>
                :
                <>
                {location.pathname === "/" ? <HomePageImageQuery className="home-background"/> : <Image src={mainImage} /> }
                </>
              }

            </div>
          </div>

        </div>
      </div>

      <div className="below-header">
        <div className="wrap">

          {content_below_header_type === 'none' && <div className="space" />}
          {content_below_header_type === 'navigation' &&
            <SideNavigation pageContext={pageContext}/>
          }

          {content_below_header_type !== 'none' && content_below_header_type !== 'navigation' &&
            <div className="secondary-header-image">
              <Image src={secondaryImage} />
            </div>
          }

          {content_below_header_type === 'slider' && slider.length > 0 &&
            <div className="slider-container">
              {sliderTabLabels && <CarouselNav items={sliderTabLabels} sliderRef={sliderRef} currentIndex={currentIndex} />}
              <div className="tns-controls">
                <button type="button" title="Prev" data-controls="prev"/>
                <button type="button" title="Next" data-controls="next"/>
              </div>

              <Carousel settings={sliderSettings} onChange={(info) => setCurrentIndex(info.index - 1)}>
              {slider.map((slide, index) =>
                <CarouselSlide slide={slide} key={index} />
              )}
              </Carousel>
            </div>
          }

          {content_below_header_type === 'text' &&
            <div className="text-content">
              {contentBelowHeading && <h2 dangerouslySetInnerHTML={{__html: decodeEntities(contentBelowHeading)}} />}

              {contentBelowRows && contentBelowRows.map((col, index) =>

                col.columns && col.columns.map((column, index) =>
                  column && column.contentType && column.contentType === 'icon-list' ?
                    <div className="icon-list" key={index}>
                      {column.iconList && column.iconList.map((icon, index) =>
                        <div className="item" key={index}>
                          <div className="icon"><Image src={icon.icon}/></div>
                          <div className="content">
                            <strong>{icon.heading}</strong>
                            <p>{icon.text}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  :
                  <div className={`content${col.columns.length > 1 ? ' two' : ' one'}`} key={index}>
                    <RenderShortcode content={column.textContent}/>
                  </div>
                )

              )}
            </div>
          }

        </div>
      </div>

    </section>
  );
}
