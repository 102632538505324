import React from 'react';
import './ImageAndEmphasisText.scss';

export const ImageAndEmphasisText = (props) => {
  return (
    <section className="content-page-header">
      <div className="wrap">
        ImageAndEmphasisText
      </div>
    </section>
  );
}
